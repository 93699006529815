* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif; */
    font-family: "Roboto", sans-serif;
}

.App {
    width: 100%;
    height: 100vh;
    /* display: flex; */
}

.Contenido {
    margin-left: 250px;
}
